<template>
  <v-container>
    <h2>Alta-voce.tech traffic</h2>
    <iframe plausible-embed src="https://a.alta-voce.tech/share/alta-voce.tech?auth=Vgw2Z0L4sntwhgocWwG-R&embed=true&theme=dark" scrolling="no" frameborder="0" loading="lazy" style="width: 1px; min-width: 100%; height: 1600px;"></iframe>
    <div style="font-size: 14px; padding-bottom: 14px;">Stats powered by <a target="_blank" style="color: transparent; text-decoration: underline;" href="https://plausible.io">Plausible Analytics</a></div>
  </v-container>
    
    
</template>

<script>
// @ is an alias to /src

export default {
  created () {
    // this.$router.push('/clients')
  }
}
</script>
